@tailwind base;
@tailwind components;
@tailwind utilities;

/******************************** GENERAL ********************************/

body {
    @apply bg-gray-50;
}

h2 {
    @apply text-3xl font-bold text-primaryPandaColor;
}

input[type=email],
input[type=password],
input[type=text],
input[type=tel],
input[type=number],
textarea,
select {
    @apply appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 focus:outline-none focus:ring-primaryPandaColor focus:border-primaryPandaColor shadow-sm sm:text-sm placeholder-gray-400;
}

input[type=text].suffix {
    @apply appearance-none block w-full px-3 py-2 border border-gray-300 rounded-l-md rounded-r-none focus:border-primaryPandaColor shadow-sm sm:text-sm placeholder-gray-400;
}


input[type=checkbox] {
    @apply h-4 w-4 text-primaryPandaColor focus:ring-transparent rounded;
}

input[type=radio] {
    @apply h-4 w-4 mt-0.5 cursor-pointer text-primaryPandaColor border-gray-300 focus:ring-secondaryPandaColor;
}

button {
    @apply relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-lightBlue-700 focus:outline-none;
}

/******************************** MENU ********************************/
.menuIcon {
    @apply w-10 h-8 ml-1;
}

button.sidebar-burger {
    @apply w-full rounded-none text-white hover:text-primaryPandaColor hover:bg-gray-700 group group flex items-center content-center lg:px-2 lg:py-2;
}

button.sidebar-link {
    @apply text-white hover:text-primaryPandaColor hover:bg-gray-700 group flex items-start lg:px-2 lg:py-2 w-full;
}

a.sidebar-logo {
    @apply hidden text-white hover:text-gray-900 hover:bg-gray-50 group lg:flex items-center bg-primaryPandaColor pt-0.5 lg:py-2;
}

a.sidebar-link {
    @apply text-white hover:text-primaryPandaColor hover:bg-gray-700 group flex items-center pt-0.5 lg:px-2 lg:py-2;
}

.groupTextIcon:hover .groupTextIcon-hover\:text-color {
    @apply text-red-500;
}

.groupTextIcon:hover span {
    display: none;
}

.groupTextIcon:hover:before {
    content: "Se déconnecter";
}

/******************************** FORM ********************************/
.react-datepicker__year-select,
.react-datepicker__month-select {
    @apply py-0 pr-6 text-xs;
}

button.react-datepicker__close-icon:after
{
    @apply bg-primaryPandaColor;
}

button.react-datepicker__close-icon:hover {
    @apply bg-transparent;
}

div.profile > div.react-datepicker-wrapper {
    @apply w-full md:w-3/5;
}

div.fullWidth > div.react-datepicker-wrapper {
    @apply w-full md:w-auto;
}

div.profile2 > div.react-datepicker-wrapper {
    @apply w-full md:w-1/3;
}

.btn {
    @apply bg-white inline-flex items-center px-2 py-1.5 md:px-4 md:py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-offset-2
}

.btn:disabled {
    @apply bg-gray-300 hover:bg-gray-300 text-gray-400 !important
}

.btn i.fas {
    @apply text-gray-400;
}

.btn i.fas + span {
    @apply ml-1;
}

.btn + .btn {
    @apply ml-2;
}

.btn:not(:disabled).btn-primary {
    @apply text-white bg-primaryPandaColor bg-gradient-to-t hover:from-secondaryPandaColor hover:to-primaryPandaColor border-none;
}

.btn:not(:disabled).btn-secondary {
    @apply text-white bg-blue-600 hover:bg-blue-700 border-none;
}

.btn:not(:disabled).btn-red {
    @apply text-white bg-red-600 hover:bg-red-700 border-none;
}

.btn:not(:disabled).btn-cancel {
    @apply leading-4 focus:ring-primaryPandaColor;
}

.pagination > button {
    @apply border-none font-medium bg-transparent text-gray-400 hover:text-gray-700 px-1.5;
}

.pagination > button:disabled {
    @apply text-gray-200 cursor-default;
}
.pagination > span {
    @apply text-gray-400 text-sm;
}

.containerInputLabel {
    @apply text-gray-700 md:flex md:items-center;
}

.containerLabel {
    @apply mb-1 md:mb-0 md:mr-2 md:w-1/3 md:flex md:justify-end
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

.react-datepicker__input-container input:disabled {
    @apply bg-gray-100;
}

input[type=number]:disabled {
    @apply bg-gray-100;
}

.notificationOpen {
    animation-duration: 1s;
    animation-name: slidein;
}

.notificationClose {
    animation-duration: 2s;
    animation-name: slideout;
}

@keyframes slidein {
    from { transform: translateX(100%); }
    to { transform: translateX(0%); }
}
@keyframes slideout {
    from { transform: translateX(0%); }
    to { transform: translateX(100%); }
}
